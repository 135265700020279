import Vue from 'vue';
import Component from 'vue-class-component';

interface Status {
  label?: string;
  tooltip?: string;
  color?: string;
  guestAction?: boolean;
  process?: number;
}

@Component
export default class StatusMixin extends Vue {
  statusMap(val: string) {
    const status: Status = {};
    switch (val) {
      case 'PENDING':
        status.label = 'Pending';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#ff9f43';
        status.guestAction = true;
        status.process = 0;
        break;
      case 'CONFIRMED':
        status.label = 'Confirmed';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#28c76f';
        status.guestAction = false;
        status.process = 1;

        break;
      case 'PENDINGCHECKIN':
        status.label = 'Pending Checkin';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#ff9f43';
        status.guestAction = true;
        status.process = 2;

        break;
      case 'CONFIRMEDCHECKIN':
        status.label = 'Confirmed Checkin';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#28c76f';
        status.guestAction = false;
        status.process = 3;

        break;
      case 'CHECKEDIN':
        status.label = 'Checked In';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#28c76f';
        status.guestAction = false;
        status.process = 4;

        break;
      case 'DAILYCHECK':
        status.label = 'Checked In';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#28c76f';
        status.guestAction = true;
        status.process = 5;

        break;
      case 'DAILYCOMPLETE':
        status.label = 'Checked In';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#28c76f';
        status.guestAction = false;
        status.process = 6;

        break;
      case 'PENDINGCHECKOUT':
        status.label = 'Pending Checkout';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#ff9f43';
        status.guestAction = true;
        status.process = 7;

        break;
      case 'CHECKEDOUT':
        status.label = 'Checked Out';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#ea5455';
        status.guestAction = false;
        status.process = 8;

        break;
      default:
        status.label = 'Pending';
        status.tooltip = 'Placeholder tooltip';
        status.color = '#ff9f43';
        status.guestAction = true;
        status.process = 0;

        break;
    }
    return status;
  }
}
