
import Vue from 'vue';
import { Component, Prop, PropSync, Ref, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import StatusMixin from '@/shared/mixins/status.mixin';
import { Route } from 'vue-router';
import client from '@/shared/plugins/content';

@Component({
  layout: 'AppMain',
  name: 'post',
})
export default class PostComponent extends mixins(StatusMixin) {
  post: any;

  created() {
    this.post = this.$route.params;
  }

  beforeRouteEnter(to: Route, _from: Route, next: () => void) {
    console.log(to);
    to.meta?.breadcrumb.push({ title: to.params.title });
    next();
  }
  beforeRouteLeave(_to: Route, from: Route, next: () => void) {
    from.meta?.breadcrumb.pop();
    next();
  }
}
